import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";

import chart01 from "web/app/images/illusts/chart01.jpg";
import chart02 from "web/app/images/illusts/chart02.jpg";
import undraw_digital_currency_qpak from "web/app/images/illusts/undraw_digital_currency_qpak.png";

import {
  AcademicCapIcon,
  ChartBarIcon,
  CloudArrowUpIcon,
  CurrencyDollarIcon,
  LockClosedIcon,
  ServerIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "リアルタイムトラッキング",
    description:
      "クリック、コンバージョン、収益をリアルタイムで追跡。日別のレポートで、あなたのアフィリエイト活動の成果を詳細に分析できます。",
    icon: CloudArrowUpIcon,
  },
  {
    name: "多様な広告",
    description:
      "多様な広告を用意しています。高コミッション広告など、あなたの戦略に合わせて最適な広告を選べます。",
    icon: LockClosedIcon,
  },
  {
    name: "パフォーマンス分析",
    description:
      "コンバージョン率や顧客行動などの詳細なデータを提供。データに基づいた意思決定で、あなたのアフィリエイトマーケティングを次のレベルに引き上げます。",
    icon: ServerIcon,
  },
];

const ibFeatures = [
  {
    name: "顧客紹介のサポート",
    description:
      "あなたが紹介した顧客の取引状況を簡単に確認できます。顧客が増えるごとにあなたの収入も増加します。",
    icon: UserGroupIcon,
  },
  {
    name: "分かりやすい報酬システム",
    description:
      "顧客の取引量に応じて報酬が発生します。取引が活発になるほど、あなたの収入も増えていきます。",
    icon: CurrencyDollarIcon,
  },
  // {
  //   name: "丁寧な教育サポート",
  //   description:
  //     "FX取引の基礎から顧客獲得のコツまで、分かりやすい教材とサポートを提供します。初心者でも安心して始められます。",
  //   icon: AcademicCapIcon,
  // },
];
// ... (previous Feature, FeaturesMobile, and FeaturesDesktop components remain unchanged)

export function SecondaryFeatures() {
  return (
    <div className="font-lp overflow-hidden bg-[#fafaf6] py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-blue-600">
                Snapaff
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                アフィリエイト収益を最大化
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                効率的なマーケティング、正確なトラッキング、迅速な支払い処理など、
                アフィリエイトビジネスの成長に欠かせない機能を全て提供します。
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        aria-hidden="true"
                        className="absolute left-1 top-1 h-5 w-5 text-blue-600"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <img
              alt="Product screenshot"
              src={chart01}
              width={600}
              className="-mb-12 max-w-none rounded-xl bg-gray-800 ring-1 ring-white/10"
            />
          </div>
        </div>

        {/* New section for IB Affiliate System (Beginner-friendly) */}
        <div className="mt-32 grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="sm:px-6 lg:px-0">
            <img
              alt="IB Partnership"
              src={chart02}
              width={600}
              className="max-w-none rounded-xl bg-gray-100 shadow-xl ring-1 ring-gray-400/10"
            />
          </div>
          <div className="px-6 lg:px-0 lg:pl-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-blue-600">
                IBアフィリエイト
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                FX取引の紹介で収入を得よう
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                IBとは「紹介ブローカー」のことです。FX取引に興味がある人を紹介するだけで収入が得られます。
                専門知識がなくても始められ、あなたの紹介で取引する人が増えるほど、収入のチャンスも広がります。
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {ibFeatures.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        aria-hidden="true"
                        className="absolute left-1 top-1 h-5 w-5 text-blue-600"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
