import { useNavigate } from "@remix-run/react";
import { Button } from "web/app/components/Button";
import { Container } from "web/app/components/Container";
import backgroundImage from "web/images/background-call-to-action.jpg";

export function CallToAction() {
  const navigate = useNavigate();
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-700 py-32"
    >
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-sans font-extralight text-3xl tracking-tight text-white sm:text-4xl">
            アフィリエイトをはじめる
          </h2>
          <p className="mt-4 text-base tracking-tight text-white">
            ブログは不要！YouTubeやXを活用してアフィリエイトを始めよう！
          </p>
          <Button
            color="white"
            size="large"
            className="mt-10"
            onClick={() => {
              navigate("/publisher/signup");
            }}
            disabled={true}
          >
            新規登録
          </Button>
        </div>
      </Container>
    </section>
  );
}
