import { Link } from "@remix-run/react";
import clsx from "clsx";

const baseStyles = {
  solid: {
    default:
      "group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
    large:
      "group inline-flex items-center justify-center rounded-lg py-3 px-6 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
  },
  outline: {
    default:
      "border-2 group inline-flex ring-1 items-center justify-center rounded-lg py-2 px-4 text-sm focus:outline-none",
    large:
      "border-2 group inline-flex ring-1 items-center justify-center rounded-lg py-3 px-6 text-lg focus:outline-none",
  },
};

const variantStyles = {
  solid: {
    slate:
      "bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
    blue: "border-blue-700 border-4 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600",
    white:
      "bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white",
  },
  outline: {
    slate:
      "ring-slate-200 text-slate-700 bg-white hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300",
    white:
      "ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white",
  },
};

type ButtonProps = (
  | {
      variant?: "solid";
      color?: keyof typeof variantStyles.solid;
      size?: "default" | "large";
    }
  | {
      variant: "outline";
      color?: keyof typeof variantStyles.outline;
      size?: "default" | "large";
    }
) &
  (
    | Omit<React.ComponentPropsWithoutRef<typeof Link>, "color">
    | (Omit<React.ComponentPropsWithoutRef<"button">, "color"> & {
        href?: undefined;
      })
  );

export function Button({ className, size = "default", ...props }: ButtonProps) {
  props.variant ??= "solid";
  props.color ??= "slate";

  className = clsx(
    baseStyles[props.variant][size],
    props.variant === "outline"
      ? variantStyles.outline[props.color]
      : variantStyles.solid[props.color],
    className,
  );

  return typeof props.href === "undefined" ? (
    <button className={className} {...props} />
  ) : (
    <Link className={className} {...props} />
  );
}
