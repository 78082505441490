import type { MetaFunction } from "@remix-run/node";
// import "../tailwind.css";

export const meta: MetaFunction = () => {
  return [
    { title: "アフィリエイトASP スナップアフィ snapaff" },
    // { name: "description", content: "Welcome to Remix!" },
  ];
};

import { CallToAction } from "web/app/components/CallToAction";
import { Faqs } from "web/app/components/Faqs";
import { Footer } from "web/app/components/Footer";
import { Header } from "web/app/components/Header";
import { Hero } from "web/app/components/Hero";
import { Pricing } from "web/app/components/Pricing";
import { PrimaryFeatures } from "web/app/components/PrimaryFeatures";
import { SecondaryFeatures } from "web/app/components/SecondaryFeatures";
import { Testimonials } from "web/app/components/Testimonials";

export default function Home() {
  return (
    <div className="bg-[#fafaf6]">
      <Header />
      <main className="bg-[#fafaf6]">
        <Hero />
        {/* <PrimaryFeatures /> */}
        <SecondaryFeatures />
        <CallToAction />
        {/* <Testimonials /> */}
        {/* <Pricing /> */}
        {/* <Faqs /> */}
      </main>
      <Footer />
    </div>
  );
}
